import React from 'react';

function UnlimitedRevisionIcon() {
    return (
        <svg
            width="42"
            height="40"
            viewBox="0 0 42 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.9167 0.163818L17.3013 7.28459L11.6535 13.559C10.9639 14.3236 10.5833 15.3171 10.5833 16.3463V35.5805C10.5833 37.8826 12.4479 39.7472 14.75 39.7472H31.4818C33.0255 39.7472 34.4232 38.8382 35.0503 37.4278L41.3044 23.3531C41.6523 22.5739 41.8333 21.7292 41.8333 20.8751V18.9138C41.8333 16.6222 39.9583 14.7472 37.6667 14.7472H23.0833C23.0833 14.7472 25.1667 9.80815 25.1667 6.12899C25.1667 0.416488 18.9167 0.163818 18.9167 0.163818ZM0.166656 14.7472V39.7472H6.41666V14.7472H0.166656Z"
                fill="#FBD501"
            />
        </svg>
    );
}

export default UnlimitedRevisionIcon;