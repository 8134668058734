import React from 'react';

function CertifiedWriterIcon() {
    return (
        <svg
            width="42"
            height="41"
            viewBox="0 0 42 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.75 0.99707C6.69582 0.99707 0.166656 6.5929 0.166656 13.4971C0.166656 15.845 0.935407 18.0327 2.24999 19.9098V30.1637L9.1022 25.0246C10.8397 25.6496 12.7479 25.9971 14.75 25.9971C22.8042 25.9971 29.3333 20.4012 29.3333 13.4971C29.3333 6.5929 22.8042 0.99707 14.75 0.99707ZM33.4512 12.61C33.4678 12.9059 33.5 13.1992 33.5 13.4971C33.5 22.6867 25.0896 30.1637 14.75 30.1637C14.7083 30.1637 14.6675 30.1556 14.6279 30.1556C17.1488 33.891 21.8521 36.4137 27.25 36.4137C29.2521 36.4137 31.1603 36.0662 32.8978 35.4412L39.75 40.5804V30.3265C41.0646 28.4494 41.8333 26.2617 41.8333 23.9137C41.8333 18.9137 38.4012 14.61 33.4512 12.61Z"
                fill="#FBD501"
            />
        </svg>
    );
}

export default CertifiedWriterIcon;