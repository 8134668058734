import React from 'react';

function ShowPasswordIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
            <g id="eye_icon_2" data-name="eye icon 2" transform="translate(21602 -2318)">
                <rect id="Rectangle_2304" data-name="Rectangle 2304" width={20} height={20} transform="translate(-21602 2318)" fill="#fff" opacity="0" />
                <path id="Subtraction_5" data-name="Subtraction 5" d="M-15,14.591a8.757,8.757,0,0,1-4.167-1.023,9.93,9.93,0,0,1-2.787-2.25A12.015,12.015,0,0,1-24,8.045,12.094,12.094,0,0,1-20.993,3.8L-19.875,5a10.354,10.354,0,0,0-2.331,3.049c.736,1.474,2.912,4.906,7.206,4.906a7.283,7.283,0,0,0,2.226-.341l1.213,1.3A8.839,8.839,0,0,1-15,14.591Zm6.01-2.317h0l-1.116-1.2A10.345,10.345,0,0,0-7.793,8.042C-8.529,6.568-10.706,3.137-15,3.137a7.285,7.285,0,0,0-2.2.333l-1.215-1.3A8.841,8.841,0,0,1-15,1.5a8.759,8.759,0,0,1,4.168,1.023,9.928,9.928,0,0,1,2.787,2.25A12.013,12.013,0,0,1-6,8.045a12.1,12.1,0,0,1-2.989,4.228ZM-15,11.318a3.277,3.277,0,0,1-3.272-3.273,3.262,3.262,0,0,1,.2-1.117l1.524,1.634a1.635,1.635,0,0,0,.931,1l1.523,1.633A3.279,3.279,0,0,1-15,11.318Zm3.086-2.18h0l-1.551-1.663a1.649,1.649,0,0,0-.856-.918l-1.552-1.665A3.279,3.279,0,0,1-15,4.773a3.277,3.277,0,0,1,3.273,3.272,3.266,3.266,0,0,1-.186,1.091Z" transform="translate(-21577 2319.5)" fill="#5f6368" />
                <rect id="Rectangle_2306" data-name="Rectangle 2306" width={20} height={2} rx="1" transform="translate(-21598.088 2319.5) rotate(47)" fill="#5f6368" />
            </g>
        </svg>
    );
}

export default ShowPasswordIcon;