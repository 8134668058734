import React from 'react';

function PlagiarismFileIcon() {
    return (
        <svg
            width="34"
            height="40"
            viewBox="0 0 34 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M32.5602 6.27999H27.1602L26.8402 1.75999C26.8402 1.55999 26.7202 1.35999 26.5612 1.19999C26.4841 1.12164 26.3896 1.06267 26.2853 1.0279C26.181 0.993141 26.0699 0.983576 25.9612 0.999992L1.40123 2.71999C0.960232 2.75999 0.601232 3.11999 0.641232 3.59999L2.80023 34.64C2.84023 35.081 3.20023 35.4 3.64023 35.4H3.68023L7.12123 35.16V38.2C7.12123 38.64 7.48123 39.04 7.96123 39.04H32.6022C33.0432 39.04 33.4432 38.681 33.4432 38.2L33.4402 7.07999C33.3592 6.63999 33.0002 6.27999 32.5592 6.27999H32.5602ZM7.12023 7.07999V33.48L4.40023 33.68L2.36023 4.27999L25.2802 2.67999L25.5202 6.23899H7.92023C7.48023 6.27899 7.12023 6.64099 7.12023 7.07899V7.07999ZM31.7202 37.36H8.76023V7.91999H31.7602V37.36H31.7202Z"
                fill="#FBD501"
            />
            <path
                d="M12.5998 13.7601H18.9598C19.3998 13.7601 19.7998 13.4001 19.7998 12.9191C19.7998 12.4781 19.4398 12.0781 18.9598 12.0781L12.5998 12.0801C12.1598 12.0801 11.7598 12.4391 11.7598 12.9201C11.7598 13.4001 12.1198 13.7601 12.5998 13.7601ZM12.5998 17.6401H27.9198C28.3608 17.6401 28.7608 17.2811 28.7608 16.8001C28.7608 16.3601 28.4018 15.9601 27.9208 15.9601H12.5998C12.1588 15.9601 11.7588 16.3191 11.7588 16.8001C11.7588 17.2801 12.1188 17.6401 12.5988 17.6401H12.5998ZM12.5998 21.5201H27.9198C28.3608 21.5201 28.7608 21.1611 28.7608 20.6801C28.7608 20.2401 28.4018 19.8401 27.9208 19.8401H12.5998C12.1588 19.8401 11.7588 20.2001 11.7588 20.6811C11.7588 21.1591 12.1188 21.5211 12.5988 21.5211L12.5998 21.5201ZM12.5998 25.4001H27.9198C28.3608 25.4001 28.7608 25.0401 28.7608 24.5601C28.7608 24.1191 28.4018 23.7191 27.9208 23.7191L12.6008 23.7201C12.1598 23.7201 11.7598 24.0801 11.7598 24.5611C11.7598 25.0411 12.1198 25.4001 12.5998 25.4001ZM12.5998 29.2801H27.9198C28.3608 29.2801 28.7608 28.9201 28.7608 28.4391C28.7608 27.9991 28.4018 27.5991 27.9208 27.5991L12.5998 27.6001C12.1588 27.6001 11.7588 27.9601 11.7588 28.4401C11.7588 28.8801 12.1188 29.2801 12.5988 29.2801H12.5998ZM27.8798 31.4801H23.7598C23.3188 31.4801 22.9188 31.8391 22.9188 32.3201C22.9188 32.8001 23.2788 33.1601 23.7588 33.1601H27.8798C28.3198 33.1601 28.7198 32.8021 28.7198 32.3201C28.7198 31.8401 28.3598 31.4801 27.8798 31.4801Z"
                fill="#FBD501"
            />
        </svg>
    );
}

export default PlagiarismFileIcon;