import React from 'react';

function DownloadFileIcon() {
    return (
        <svg width={14} height={15} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9.99984V12.6665C1 13.3998 1.6 13.9998 2.33333 13.9998H11.6667C12.0203 13.9998 12.3594 13.8594 12.6095 13.6093C12.8595 13.3593 13 13.0201 13 12.6665V9.99984M10.3333 5.99984L7 9.33317L3.66667 5.99984M7 8.53317V1.6665" stroke="#12245A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default DownloadFileIcon;