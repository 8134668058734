export { default as CloseIcon } from './closeIcon';
export { default as RightArrowIcon } from './rightArrowIcon';
export { default as HidePasswordIcon } from './hidePasswordIcon';
export { default as ShowPasswordIcon } from './showPasswordIcon';
export { default as HeaderIcon } from './headerIcon';
export { default as DownloadPdfIcon } from './downloadPdfIcon';
export { default as BackIcon } from './backIcon';
export { default as CopyIcon } from './copyIcon';
export { default as PaymentCardIcon } from './paymentCardIcon';
export { default as FileIcon } from './fileIcon';
export { default as ViewIcon } from './viewIcon';
export { default as RemoveFileIcon } from './removeFileIcon';
export { default as DownloadFileIcon } from './downloadFileIcon';
export { default as SearchIcon } from './searchIcon';
export { default as InfoIcon } from './infoIcon';
export { default as ProfileEditIcon } from './profileEditIcon';
export { default as SortIcon } from './sortIcon';
export { default as TableOfContentIcon } from './tableOfContentIcon';
export { default as UnlimitedRevisionIcon } from './unlimitedRevisionIcon';
export { default as PlagiarismFreeIcon } from './plagiarismFreeIcon';
export { default as CertifiedWriterIcon } from './certifiedWriterIcon';
export { default as PlagiarismFileIcon } from './plagiarismFileIcon';
export { default as GuaranteeIcon } from './GuaranteeIcon';
export { default as LanguageSupportIcon } from './languageSupportIcon';
export { default as CheckingIcon } from './checkingIcon';
export { default as StarIcon } from './starIcon';

