import React from 'react';

function ProfileEditIcon() {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.33333 1.3335L12 4.00016L4.66667 11.3335H2V8.66683L9.33333 1.3335Z"
                stroke="#151515"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2 14.6665H14"
                stroke="#151515"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default ProfileEditIcon;