import React from 'react';

function CopyIcon() {
    return (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.3335 10.0002H2.66683C2.31321 10.0002 1.97407 9.85969 1.72402 9.60964C1.47397 9.35959 1.3335 9.02045 1.3335 8.66683V2.66683C1.3335 2.31321 1.47397 1.97407 1.72402 1.72402C1.97407 1.47397 2.31321 1.3335 2.66683 1.3335H8.66683C9.02045 1.3335 9.35959 1.47397 9.60964 1.72402C9.85969 1.97407 10.0002 2.31321 10.0002 2.66683V3.3335" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default CopyIcon;