import React from 'react';

function BackIcon({ size, stroke }) {
    return (
        <svg
            width={ size || 12}
            height={ size || 12}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.99935 1.3335L1.33268 6.00016L5.99935 10.6668M10.666 6.00016H1.99935H10.666Z"
                stroke={stroke || "#12245A"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default BackIcon;