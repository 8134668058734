import React from 'react';

function ViewIcon() {
    return (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M0.666504 7.99984C0.666504 7.99984 3.33317 2.6665 7.99984 2.6665C12.6665 2.6665 15.3332 7.99984 15.3332 7.99984C15.3332 7.99984 12.6665 13.3332 7.99984 13.3332C3.33317 13.3332 0.666504 7.99984 0.666504 7.99984Z" stroke="#151515" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke="#151515" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={16} height={16} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default ViewIcon;