import React from 'react';

function FileIcon() {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.9085 0C16.0525 0 18.1645 2.153 18.1645 5.357V14.553C18.1645 17.785 16.1175 19.887 12.9495 19.907L5.25651 19.91C2.11251 19.91 -0.000488281 17.757 -0.000488281 14.553V5.357C-0.000488281 2.124 2.04651 0.023 5.21451 0.004L12.9075 0H12.9085ZM12.9085 1.5L5.21951 1.504C2.89151 1.518 1.49951 2.958 1.49951 5.357V14.553C1.49951 16.968 2.90451 18.41 5.25551 18.41L12.9445 18.407C15.2725 18.393 16.6645 16.951 16.6645 14.553V5.357C16.6645 2.942 15.2605 1.5 12.9085 1.5ZM12.7156 13.4737C13.1296 13.4737 13.4656 13.8097 13.4656 14.2237C13.4656 14.6377 13.1296 14.9737 12.7156 14.9737H5.49561C5.08161 14.9737 4.74561 14.6377 4.74561 14.2237C4.74561 13.8097 5.08161 13.4737 5.49561 13.4737H12.7156ZM12.7156 9.2872C13.1296 9.2872 13.4656 9.6232 13.4656 10.0372C13.4656 10.4512 13.1296 10.7872 12.7156 10.7872H5.49561C5.08161 10.7872 4.74561 10.4512 4.74561 10.0372C4.74561 9.6232 5.08161 9.2872 5.49561 9.2872H12.7156ZM8.25031 5.1104C8.66431 5.1104 9.00031 5.4464 9.00031 5.8604C9.00031 6.2744 8.66431 6.6104 8.25031 6.6104H5.49531C5.08131 6.6104 4.74531 6.2744 4.74531 5.8604C4.74531 5.4464 5.08131 5.1104 5.49531 5.1104H8.25031Z" fill="black" />
        </svg>
    );
}

export default FileIcon;