import React from 'react';

function CheckingIcon() {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
        >
            <path
                d="M47.6083 14.5469L44.9499 15.778C46.3569 18.8162 47.0704 21.919 47.0704 25.0002C47.0704 37.1698 37.1697 47.0705 25 47.0705C20.5595 47.0705 16.2791 45.7033 12.5442 43.1061C13.1229 41.5446 12.7873 39.7182 11.5346 38.4655C9.82124 36.7521 7.03315 36.7522 5.31978 38.4655C3.6063 40.1789 3.6063 42.9669 5.31978 44.6804C6.76089 46.1216 8.96821 46.3539 10.6565 45.3605C14.9339 48.3994 19.8687 50.0002 25 50.0002C38.7656 50.0002 50 38.7649 50 25.0002C50 21.4907 49.1954 17.9737 47.6083 14.5469ZM9.46304 42.6089C8.89185 43.1801 7.96274 43.1802 7.39136 42.6089C6.82017 42.0377 6.82017 41.1084 7.39136 40.5373C7.96235 39.9662 8.89175 39.966 9.46304 40.5373C10.0342 41.1083 10.0342 42.0377 9.46304 42.6089Z"
                fill="#fff"
            />
            <path
                d="M32.3242 19.1406V14.7461H35.2539V11.8164H14.7461V14.7461H17.6758V19.1406C17.6758 21.534 18.8299 23.6626 20.6105 25C18.8299 26.3374 17.6758 28.466 17.6758 30.8594V35.2539H14.7461V38.1836H35.2539V35.2539H32.3242V30.8594C32.3242 28.466 31.1701 26.3374 29.3895 25C31.1701 23.6626 32.3242 21.534 32.3242 19.1406ZM29.3945 30.8594V35.2539H20.6055V30.8594C20.6055 28.4362 22.5769 26.4648 25 26.4648C27.4231 26.4648 29.3945 28.4362 29.3945 30.8594ZM29.3945 19.1406C29.3945 21.5638 27.4231 23.5352 25 23.5352C22.5769 23.5352 20.6055 21.5638 20.6055 19.1406V14.7461H29.3945V19.1406Z"
                fill="#fff"
            />
            <path
                d="M38.4654 11.5347C40.1787 13.2479 42.9668 13.248 44.6803 11.5347C46.3937 9.82129 46.3937 7.0332 44.6803 5.31982C43.2358 3.87539 41.0277 3.64863 39.3436 4.63965C35.0661 1.60078 30.1313 0 25 0C11.2345 0 0 11.2353 0 25C0 28.5095 0.804688 32.0265 2.3917 35.4533L5.0502 34.2222C3.64316 31.184 2.92969 28.0812 2.92969 25C2.92969 12.8304 12.8304 2.92969 25 2.92969C29.4405 2.92969 33.721 4.29688 37.4559 6.89414C36.8771 8.45557 37.2128 10.282 38.4654 11.5347ZM40.537 7.39141C41.1082 6.82031 42.0374 6.82022 42.6087 7.39141C43.1799 7.9626 43.1799 8.89189 42.6087 9.46299C42.0375 10.0342 41.1082 10.0343 40.537 9.46299C39.9658 8.89189 39.9658 7.9625 40.537 7.39141Z"
                fill="#fff"
            />
        </svg>
    );
}

export default CheckingIcon;