import React from 'react';

function PlagiarismFreeIcon() {
    return (
        <svg
            width="44"
            height="45"
            viewBox="0 0 44 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19 0.99707C16.7899 0.99707 14.6702 1.87504 13.1074 3.43785C11.5446 5.00065 10.6667 7.12027 10.6667 9.3304C10.6667 11.5405 11.5446 13.6602 13.1074 15.223C14.6702 16.7858 16.7899 17.6637 19 17.6637C21.2101 17.6637 23.3298 16.7858 24.8926 15.223C26.4554 13.6602 27.3333 11.5405 27.3333 9.3304C27.3333 7.12027 26.4554 5.00065 24.8926 3.43785C23.3298 1.87504 21.2101 0.99707 19 0.99707ZM19 23.9137C12.4562 23.9137 0.25 27.1346 0.25 33.2887V38.4971H21.4414L32.7451 27.1893C28.6159 25.0289 22.8396 23.9137 19 23.9137ZM40.2646 25.9971C39.9982 25.9971 39.7313 26.0991 39.5282 26.3022L37.4448 28.3856L41.6115 32.5522L43.6948 30.4689C44.1011 30.0627 44.1011 29.4022 43.6948 28.9959L41.0011 26.3022C40.797 26.0991 40.5311 25.9971 40.2646 25.9971ZM35.9718 29.8586L25.25 40.5804V44.7471H29.4167L40.1385 34.0252L35.9718 29.8586Z"
                fill="#FBD501"
            />
        </svg>
    );
}

export default PlagiarismFreeIcon;