import React from 'react';

function HidePasswordIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
            <g id="eye_icon_" data-name="eye icon " transform="translate(21602 -2318)">
                <rect id="Rectangle_2304" data-name="Rectangle 2304" width={20} height={20} transform="translate(-21602 2318)" fill="#fff" opacity="0" />
                <path id="eye_3_" data-name="eye (3)" d="M10,4c-6.545,0-9,6.545-9,6.545s2.455,6.545,9,6.545,9-6.545,9-6.545S16.545,4,10,4Zm0,1.636c4.317,0,6.5,3.491,7.207,4.906C16.5,11.947,14.3,15.455,10,15.455c-4.317,0-6.5-3.491-7.207-4.906C3.5,9.144,5.7,5.636,10,5.636Zm0,1.636a3.273,3.273,0,1,0,3.273,3.273A3.273,3.273,0,0,0,10,7.273Zm0,1.636a1.636,1.636,0,1,1-1.636,1.636A1.636,1.636,0,0,1,10,8.909Z" transform="translate(-21602 2317)" fill="#5f6368" />
            </g>
        </svg>
    );
}

export default HidePasswordIcon;