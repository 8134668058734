import React from 'react';

function SortIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11.031"
            height="6.311"
            viewBox="0 0 11.031 6.311"
            className="ms-2"
        >
            <path
                id="Path_605"
                data-name="Path 605"
                d="M1277.09,8417.86l4.46,4.5,4.45-4.5"
                transform="translate(-1276.029 -8416.8)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}

export default SortIcon;